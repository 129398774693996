<template>
  <div class="notification-wrapper">
    <h1 class="message-wrapper">{{ message }}</h1>
    <AppLinkButton name="catalogue" label="Перейти в каталог"/>
  </div>
</template>

<script>
import AppLinkButton from "@/components/ui/buttons/AppLinkButton";
import { unsubscribePromoMailing } from "@/API/promo-api";


export default {
  name: "Unsubscribe",
  components: {
    AppLinkButton
  },
  data() {
    return {
      message: 'Вы успешно отписались от рассылок The Mantra',
    }
  },
  async created() {
    const { id } = this.$route.params;
    const response =  await unsubscribePromoMailing(id);
    if (response) this.message = response;
  }
}
</script>

<style>
/* Global Style main.css */
</style>