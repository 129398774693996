<template>
  <router-link :to="{ name }" class="app-link-btn">
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
         viewBox="0 0 27 27" v-if="arrow" class="app-link-btn__arrow">
      <g id="west-24px" transform="translate(-0.234 0.106)">
        <rect id="Rectangle_988" data-name="Rectangle 988" width="27" height="27" transform="translate(0.234 -0.106)" fill="none"/>
        <path id="Path_1285" data-name="Path 1285" d="M9.968,20.937l1.605-1.605L6.36,14.107H24.767V11.83H6.36l5.225-5.225L9.968,5,2,12.968Z" transform="translate(0.319 0.672)" fill="#fff"/>
      </g>
    </svg>
    <span class="app-link-btn__label">{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    arrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    }
  }
}
</script>

<style>
.app-link-btn {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 40px;
  padding: 10px 15px;
  border: 2px solid var(--main-red);
  border-radius: 34px;
}

.app-link-btn__arrow {
  margin-right: 5px;
}

.app-link-btn:hover {
  background-color: var(--main-red);
  cursor: pointer;
}
</style>